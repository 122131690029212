<template>
  <div class="MemolifeTvView">
    <WidthWrapper set-vars-on-parent />

    <div class="section">
      <MixedVideosRow />
    </div>
    <CategoriesSection />
  </div>
</template>

<translations>
</translations>

<script>
import MixedVideosRow from './MixedVideosRow';
import CategoriesSection from './CategoriesSection';
import WidthWrapper from '../../components/WidthWrapper';

export default {
  components: { MixedVideosRow, CategoriesSection, WidthWrapper },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (from.name === 'DashboardVideoView') {
        await vm.scrollToState();
      } else if (from.name !== 'DashboardVideoView') {
        vm.$store.commit('moduleApp/updateVideosPageState', {
          selectedCategoryId: null,
          selectedLifeskillId: null,
          selectedVideoId: null,
        });
      }
    });
  },
  watch: {
    '$store.state.moduleApp.videosPage': {
      async handler() {
        this.scrollToState(true);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('moduleApp/loadAllVideos');
    this.$store.dispatch('moduleApp/loadRecommendedVideos');
  },
  methods: {
    async scrollToState(smooth) {
      const { selectedCategoryId, selectedLifeskillId, selectedVideoId } = this.$store.state.moduleApp.videosPage;

      await this.$nextTick();
      if (selectedCategoryId) {
        const target = this.$el.querySelector(`.CategoryRow[data-category-id="${selectedCategoryId}"]`);

        if (selectedLifeskillId && !smooth) {
          const lifeskillTarget = target.querySelector(`.LifeskillItem[data-lifeskill-id="${selectedLifeskillId}"]`);
          if (lifeskillTarget) {
            const scrollContainer = target.querySelector('.FullWidthHorizontalScroll');
            if (scrollContainer) {
              scrollContainer.scrollLeft =
                lifeskillTarget.offsetLeft - scrollContainer.clientWidth / 2 + lifeskillTarget.clientWidth / 2;
            }
          }
        }

        if (selectedVideoId) {
          const videoTarget = target.querySelector(`.VideoItem[data-video-id="${selectedVideoId}"]`);
          if (videoTarget) {
            videoTarget.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto', block: 'nearest' });
          }
        } else {
          target.scrollIntoView({ behavior: smooth ? 'smooth' : 'auto' });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.MemolifeTvView {
  overflow-y: auto;
}
.title {
  font-size: 140%;
}
.section {
  padding-left: calc(var(--widthWrapperSideWidth) * 1px);
  padding-right: calc(var(--widthWrapperSideWidth) * 1px);
  padding-top: 2em;
}
.Banner {
  margin-top: 2em;
}
.CategoriesSection {
  margin-top: 5em;
}
</style>
