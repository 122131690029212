<template>
  <div class="LifeskillVideos">
    <div
      v-if="showHeader"
      class="header">
      <div class="title">
        {{ $t(`dashboard.general.lifeskillLabels.${lifeskillId}`) }}
      </div>
      <div
        class="collapseButton --global-clickable"
        @click="$emit('request-collapse')">
        <svgicon
          class="collapseButtonIcon"
          icon="close" />
      </div>
    </div>
    <div
      v-for="playlist in playlists"
      :key="playlist.name"
      class="Playlist">
      <div class="title">
        {{ playlist.name }} <span class="count">{{ playlist.videos.length }} {{ $t('videos') }}</span>
      </div>
      <FullWidthHorizontalScroll>
        <div class="items">
          <VideoItem
            v-for="v of playlist.videos"
            :key="v.id"
            :video-id="v.id"
            :lifeskill-id="lifeskillId"
            hide-lifeskill-tag
            :class="{ noboxshadow }"
            @clicked-video="handleClickedVideo" />
        </div>
      </FullWidthHorizontalScroll>
    </div>
  </div>
</template>

<translations>
  showMore: 'Show more videos'
  showMore_no: 'Vis flere videoer'
  videos: videos
  videos_no: videoer
</translations>

<script>
import _ from 'lodash';
import VideoItem from './VideoItem';
import FullWidthHorizontalScroll from '../../components/FullWidthHorizontalScroll';

export default {
    components: { VideoItem, FullWidthHorizontalScroll },
    props: {
        lifeskillId: {
            type: String,
            required: true
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        noboxshadow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    computed: {
        playlists() {
            const videos = this.$store.getters['moduleApp/getVideosForLifeskill'](this.lifeskillId);

            return _.chain(videos)
                .groupBy('playlist')
                .map((v, i) => {
                    return {
                        name: i,
                        videos: _.sortBy(v, v => parseInt(v.ordering))
                    };
                })
                .value()
                .sort(playlistSort);
        },

        lifeskill() {
            return this.$store.getters['moduleApp/getLifeskillById'](this.lifeskillId);
        }
    },
    mounted() {
        const id = this.$store.getters['moduleApp/getLifeskillIdByNumbershape'](this.lifeskillId);

        return this.$store.dispatch('moduleApp/loadVideosForLifeskill', { lifeskillId: id });
    },
    methods: {
        handleClickedVideo(videoId) {
            this.$store.commit('moduleApp/updateVideosPageState', {
                selectedVideoId: videoId
            });
        }
    }
};

const playlistSort = (a, b) => {
    const ordering = {
        'Mixed Up': 1,
        Blandet: 1,
        BrainCraft: 2,
        BigThink: 3,
        'TED-Ed': 4,
        TED: 5,
        'Chill & Deep Dive': 6,
        'Slapp av og dypdykk': 6
    };

    return (ordering[a.name] || 10) - (ordering[b.name] || 10);
};
</script>

<style lang="scss" scoped>
.header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: linear-gradient(180deg, $dashboard-darkSectionBackground 80%, transparent);
    padding-bottom: 1em;
    padding-top: 1em;

    display: flex;
    align-items: baseline;
}
.title {
    font-size: $dashboard-sectionTitleFontSize;
    flex: 1;
}

.Playlist {
    margin-top: 1em;
    .title {
        font-size: 120%;
        flex: 1;

        display: flex;

        .count {
            color: #9FB4EF;
            margin-left: 1em;

            flex: 1;
            text-align: right;
        }
    }
}

.collapseButton {
    margin-left: 1em;
    margin-right: 1em;
    position: relative;
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: -1em;
        right: -1em;
        bottom: -1em;
        left: -1em;
    }
}
.collapseButtonIcon {
    $size: 0.8em;
    width: $size;
    height: $size;
    fill: white;
}
.items {
    display: flex;
}
.footer {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}
.showMoreButton {
    color: rgba(white, 0.5);
}

.FullWidthHorizontalScroll {
    margin-top: 0.5em;
}

.VideoItem {
    width: 19em;
    flex-shrink: 0;
    &:not(:first-child) {
        margin-left: $dashboard-itemGapWidth;
    }
    .title {
        color: rgba(255, 255, 255, 0.7);
    }

    &.noboxshadow /deep/ .card {
        box-shadow: none;
    }
}
</style>
