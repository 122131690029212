<template>
  <div class="CategoriesSection">
    <div class="header">
      <div class="title">
        {{ $t('title') }}
      </div>
      <div class="text">
        {{ $t('text') }}
      </div>
    </div>
    <div class="rows">
      <div
        v-for="categoryId of categoryIds"
        :key="categoryId"
        class="section">
        <CategoryRow
          :category-id="categoryId"
          :selected-lifeskill-id="selectedLifeskillId" />
      </div>
    </div>
  </div>
</template>

<translations>
  title: 'Explore Videos from the Ecosystem'
  title_no: 'Utforsk videoer fra økosystemet'
  text: 'We''re developing accelerated learning and habit change of 100 life skills. Enjoy brain dates from thousands of videos in the meantime.'
  text_no: 'Vi utvikler akselerert læring og vaneendring av 100 ferdigheter. Kos deg med brain dates fra tusenvis av videoer så lenge.'
</translations>

<script>
import CategoryRow from './CategoryRow';

export default {
    components: { CategoryRow },
    data() {
        return {
            selectedLifeskillId: null
        };
    },
    computed: {
        categoryIds() {
            return Object.keys(this.$store.state.moduleApp.categories);
        }
    }
};
</script>

<style lang="scss" scoped>
.CategoriesSection {
}
.header {
    padding-left: calc(var(--widthWrapperSideWidth) * 1px);
    padding-right: calc(var(--widthWrapperSideWidth) * 1px);
}
.title {
    font-size: $dashboard-sectionTitleFontSize;
    color: white;
}
.text {
    margin-top: 0.1em;
    color: rgba(white, 0.7);
    line-height: 1.5em;
}

.rows {
    margin-top: 2em;
}
.section {
    padding-left: calc(var(--widthWrapperSideWidth) * 1px);
    padding-right: calc(var(--widthWrapperSideWidth) * 1px);
}
</style>
