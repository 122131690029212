<template>
  <div class="MixedVideosRow">
    <div class="header">
      <div
        class="Tab --global-clickable"
        :class="{ active: activeTab === 'recommended' }"
        @click="activeTab = 'recommended'">
        {{ $t('recommended') }}
      </div>
      <div
        class="Tab --global-clickable"
        :class="{ active: activeTab === 'inProgress' }"
        @click="activeTab = 'inProgress'">
        {{ $t('inProgress') }} ({{ inProgressCount }})
      </div>
    </div>

    <FullWidthHorizontalScroll :key="activeTab">
      <div class="items">
        <VideoItem
          v-for="{ videoId, lifeskillId, title, duration, thumbnail } of items"
          :key="videoId"
          :video-id="videoId"
          :lifeskill-id="lifeskillId"
          :video-title="title"
          :video-duration="duration"
          :video-thumbnail="thumbnail" />
      </div>
    </FullWidthHorizontalScroll>
  </div>
</template>

<translations>
  recommended: 'Popular'
  recommended_no: 'Populært'

  inProgress: 'In progress'
  inProgress_no: 'Se ferdig'
</translations>

<script>
import FullWidthHorizontalScroll from '../../components/FullWidthHorizontalScroll';
import VideoItem from './VideoItem';
import { mapGetters } from 'vuex';

export default {
    components: { FullWidthHorizontalScroll, VideoItem },
    props: {},
    data() {
        return {
            activeTab: 'recommended'
        };
    },
    computed: {
        ...mapGetters({
            recommended: 'moduleApp/recommendedVideos',
            inProgress: 'moduleApp/inProgressVideoItems',
        }),

        items() {
            if (this.activeTab === 'recommended') {
                return this.recommended;
            } else {
                return this.inProgress;
            }
        },

        inProgressCount() {
          return this.inProgress.length;
        }
    },
    mounted() {
        this.$store.dispatch('moduleApp/loadVideosInProgress');
    }
};
</script>

<style lang="scss" scoped>
.MixedVideosRow {
}
.header {
    display: flex;
    align-items: center;
}

.Tab {
    font-size: $dashboard-sectionTitleFontSize;
    color: rgba(white, 0.5);
    &.active {
        color: white;
    }
    &:not(:first-child) {
        margin-left: 1em;
    }
}

.FullWidthHorizontalScroll {
    margin-top: 2em;
}

.items {
    display: flex;
}

.VideoItem {
    width: 19em;
    flex-shrink: 0;
    &:not(:first-child) {
        margin-left: $dashboard-itemGapWidth;
    }
}
</style>
