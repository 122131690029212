<template>
  <div
    class="LifeskillItem"
    :class="{ selected, empty: numberOfVideos === 0 }"
    :data-lifeskill-id="lifeskillId"
    @mouseover="animateNumbershape = true"
    @mouseleave="animateNumbershape = false">
    <div class="icon">
      <AnimatedNumbershape
        class="number"
        :number="lifeskillId"
        :play="animateNumbershape"
        color="white"
        reversed />
    </div>
    <div class="textContainer">
      <div class="label">
        {{ $t(`dashboard.general.lifeskillLabels.${lifeskillId}`) }}
      </div>
      <div class="videoCount">
        {{ numberOfVideos }} {{ $tc('dashboard.general.video', numberOfVideos) }}
      </div>
    </div>

    <LifeskillNumberTag
      class="tag"
      :lifeskill-id="lifeskillId" />
    <div class="funnel" />
  </div>
</template>

<script>
import AnimatedNumbershape from '@/components/AnimatedNumbershape';
import LifeskillNumberTag from '../../components/LifeskillNumberTag';

export default {
    components: { AnimatedNumbershape, LifeskillNumberTag },
    props: {
        lifeskillId: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            animateNumbershape: false
        };
    },
    computed: {
        numberOfVideos() {
            return this.$store.state.moduleApp.lifeskills[this.lifeskillId].numberOfRelatedVideos;
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.3s;
$expandedSectionBackgroundColor: $dashboard-darkSectionBackground;

.LifeskillItem {
    padding: 1em;
    background: linear-gradient(180deg, #0F153B 0%, #0C0F31 100%);
    color: white;
    display: flex;
    align-items: flex-start; //center;
    border-radius: $dashboard-itemBorderRadius;
    // border: 1px solid #1F4497;
    position: relative;

    margin-bottom: 2em;

    &.selected {
        border-color: #4e8dbc;
        // background-color: black;
        //
        .funnel {
            $height: 1em;
            border-bottom-width: $height;
        }
    }

    &:hover {
        .icon {
            transform: scale(1.2);
        }
    }
}

.icon {
    $size: 4em;
    width: $size;
    height: $size;
    flex-shrink: 0;
    margin-right: 1em;
    transition: transform $transitionDuration;
    transform: scale(1.001);
    align-self: center;
}
.textContainer {
    flex: 1;
    align-self: center;
}
.label {
    hyphens: auto;
}
.videoCount {
    color: $dashboard-smallTextColor;
    margin-top: 0.5em;
    font-size: 90%;
}
.LifeskillNumberTag {
    margin-left: 0.5em;
}
.funnel {
    $width: 1.8em;
    $height: 0em;
    width: $width;
    position: absolute;
    bottom: -2em;
    left: calc(50% - 1em);
    border-left: $width / 2 solid transparent;
    border-right: $width / 2 solid transparent;
    border-bottom: $height solid $expandedSectionBackgroundColor;

    // transition: border-bottom-width 0.3s;
}
</style>
