<template>
  <div
    class="CategoryRow"
    :data-category-id="categoryId">
    <div class="header">
      <div class="title">
        {{ $t(`dashboard.general.categoryLabels.${categoryId}`) }} {{ lifeskillRange }}
      </div>
    </div>

    <FullWidthHorizontalScroll>
      <div class="items">
        <LifeskillItem
          v-for="lifeskillId of lifeskillIds"
          :key="lifeskillId"
          class="Item --global-clickable"
          :selected="selectedLifeskillId === lifeskillId"
          :lifeskill-id="lifeskillId"
          @click.native="onLifeskillItemClicked(lifeskillId)" />
      </div>
    </FullWidthHorizontalScroll>

    <div
      v-if="selectedLifeskillId"
      class="videosSection">
      <LifeskillVideos
        :key="selectedLifeskillId"
        :lifeskill-id="selectedLifeskillId"
        @request-collapse="onLifeskillItemClicked(null)" />
    </div>
  </div>
</template>

<script>
import { getLifeskillRangeFromCategoryId } from '@/utils';
import FullWidthHorizontalScroll from '../../components/FullWidthHorizontalScroll';
import LifeskillItem from './LifeskillItem';
import LifeskillVideos from './LifeskillVideos';

export default {
    components: { FullWidthHorizontalScroll, LifeskillItem, LifeskillVideos },
    props: {
        categoryId: {
            type: String,
            required: true
        }
    },
    computed: {
        lifeskillRange() {
            return getLifeskillRangeFromCategoryId(this.categoryId).join('-');
        },
        selectedLifeskillId() {
            const selectedLifeskillId = this.$store.state.moduleApp.videosPage.selectedLifeskillId;
            return this.lifeskillIds.indexOf(selectedLifeskillId) !== -1 ? selectedLifeskillId : null;
        }
    },
    created() {
        this.lifeskillIds = this.$store.getters['moduleApp/getCategoryById'](this.categoryId).lifeskills;
    },
    methods: {
        onLifeskillItemClicked(lifeskillId) {
            this.$store.commit('moduleApp/updateVideosPageState', {
                selectedCategoryId: this.categoryId,
                selectedLifeskillId: this.selectedLifeskillId === lifeskillId ? null : lifeskillId,
                selectedVideoId: null
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.CategoryRow {
}
.header {
    padding-top: 1em;
    display: flex;
    align-items: center;
}
.title {
    font-size: 120%;
    color: rgba(white, 0.9);
}
.FullWidthHorizontalScroll {
    margin-top: 0.5em;
    padding-bottom: 0em;
}
.items {
    display: flex;
}

.LifeskillItem {
    width: 17em;
    flex-shrink: 0;
    &:not(:first-child) {
        margin-left: $dashboard-itemGapWidth;
    }
}
.videosSection {
    margin-left: calc(var(--widthWrapperSideWidth) * -1px);
    padding-left: calc(var(--widthWrapperSideWidth) * 1px);

    margin-right: calc(var(--widthWrapperSideWidth) * -1px);
    padding-right: calc(var(--widthWrapperSideWidth) * 1px);

    background-color: $dashboard-darkSectionBackground;
    padding-top: 1em;
    padding-bottom: 2em;
}
</style>
